import { useState } from 'react';

import Navbar from '../components/navbar/Navbar';
import CalendarContainer from '../components/calendar/CalendarContainer';
import CardContainer from '../components/cards/CardContainer';
import DetailsContainer from '../components/details/DetailsContainer';
import OverlayContainer from '../components/overlay/OverlayContainer';
import { Mobile, Tablet, Desktop } from '../styles/Responsive';
import TabNav from '../components/tabnav/TabNav';
import MobileMenu from '../components/MobileMenu/MobileMenu';

export default function Meetings() {
  const [createMeetingModal, setCreateMeetingModal] = useState(true);
  const toggleMeetingModal = () => setCreateMeetingModal((value) => !value);

  return (
    <>
      <OverlayContainer
        createMeetingModal={createMeetingModal}
        setCreateMeetingModal={setCreateMeetingModal}
        toggleMeetingModal={toggleMeetingModal}
      >
        <h1> Awesome modal </h1>
      </OverlayContainer>
      <Mobile>
        <div className="m-container">
          <MobileMenu />
          <div className="m-content__grid meetings">
            <div className="m-card__container">
              <CardContainer />
            </div>
            <div className="m-details__container">
              <DetailsContainer />
            </div>
          </div>
          <TabNav />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-container">
          <MobileMenu />
          <div className="t-content__grid meetings">
            <div className="t-calendar__container">
              <CalendarContainer />
            </div>
            <div className="t-card__container">
              <CardContainer />
            </div>
            <div className="t-details__container">
              <DetailsContainer />
            </div>
          </div>
          <TabNav />
        </div>
      </Tablet>
      <Desktop>
        <div className="d-container">
          <div className="d-content__grid meetings">
            <Navbar />
            <div className="d-calendar__container">
              <CalendarContainer />
            </div>
            <div className="d-card__container">
              <CardContainer toggleMeetingModal={toggleMeetingModal} />
            </div>
            <div className="d-details__container">
              <DetailsContainer />
            </div>
          </div>
        </div>
      </Desktop>
    </>
  );
}
