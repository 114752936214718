import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './calendar.scss';
import Calendar from './Calendar';
import DropdownContainer from '../dropdown/DropdownContainer';

export default function CalendarContainer() {
  // console.log('rendering CalendarContainer');
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const date = new Date();
  // const [currentDate, setCurrentDate] = useState();
  // const [currentDay, setCurrentDay] = useState();
  // const [currentDayOfWeek, setCurrentDayOfWeek] = useState();
  // const [currentMonth, setCurrentMonth] = useState();
  // const [currentSumOfDaysInMonth, setCurrentSumOfDaysInMonth] = useState();
  const [currentArrDaysInMonth, setCurrentArrDaysInMonth] = useState([]);
  const [currentArrDayNames, setCurrentArrDayNames] = useState([]);
  // const [currentYear, setCurrentYear] = useState();
  const [currentArrYears, setCurrentArrYears] = useState([]);
  const [activeYear, setActiveYear] = useState(date.getFullYear());
  const [activeMonth, setActiveMonth] = useState(months[date.getMonth()]);
  const [activeDay, setActiveDay] = useState(date.getDate());

  // function to create arrays of daysInMonth and dayNamesInMonth
  const createDaysArr = (
    sumOfDaysInMonth,
    arrOfDaysInMonth,
    arrOfDayNamesInMonth,
    activeYear,
    activeMonth
  ) => {
    for (let i = 0; i < sumOfDaysInMonth; i++) {
      arrOfDaysInMonth.push(i + 1);
    }
    for (let h = 1; h <= sumOfDaysInMonth; h++) {
      var d = new Date(activeYear, months.indexOf(activeMonth), h);
      var dayName = days[d.getDay()];
      arrOfDayNamesInMonth.push(dayName);
    }
  };

  // function to create array of years for dropdown
  const createArrOfYears = (arrOfYears) => {
    let max = new Date().getFullYear() + 10;
    let min = max - 11;
    for (let i = min; i <= max; i++) {
      arrOfYears.push(i);
    }
  };

  // get initial dates
  const initializeDates = () => {
    // get date, sumOfDaysInMoth and run functions to create
    // arrays of daysInMonth, dayNamesInMonth and years
    const date = new Date();
    let arrOfDaysInMonth = [];
    let arrOfDayNamesInMonth = [];
    let sumOfDaysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    let arrOfYears = [];
    createDaysArr(
      sumOfDaysInMonth,
      arrOfDaysInMonth,
      arrOfDayNamesInMonth,
      activeYear,
      activeMonth
    );
    createArrOfYears(arrOfYears);

    // setCurrentDate(date);
    // setCurrentDay(date.getDate());
    // setCurrentDayOfWeek(days[date.getDay()]);
    // setCurrentMonth(months[date.getMonth()]);
    // setCurrentSumOfDaysInMonth(sumOfDaysInMonth);
    setCurrentArrDaysInMonth(arrOfDaysInMonth);
    setCurrentArrDayNames(arrOfDayNamesInMonth);
    // setCurrentYear(date.getFullYear());
    setCurrentArrYears(arrOfYears);
  };
  useEffect(() => {
    initializeDates();
  }, []);

  // get active dates
  const getActiveDates = () => {
    // set activeDates, sumOfDaysInMoth and run functions to create
    // arrays of daysInMonth, dayNamesInMonth
    let arrOfDaysInMonth = [];
    let arrOfDayNamesInMonth = [];
    let sumOfDaysInMonth = new Date(
      activeYear,
      months.indexOf(activeMonth) + 1,
      0
    ).getDate();
    createDaysArr(
      sumOfDaysInMonth,
      arrOfDaysInMonth,
      arrOfDayNamesInMonth,
      activeYear,
      activeMonth
    );
    // setCurrentSumOfDaysInMonth(sumOfDaysInMonth);
    setCurrentArrDaysInMonth(arrOfDaysInMonth);
    setCurrentArrDayNames(arrOfDayNamesInMonth);
  };
  useEffect(() => {
    getActiveDates();
  }, [activeYear, activeMonth]);

  // console.log('currentDate: ', currentDate);
  // console.log('currentDay: ', currentDay);
  // console.log('currentDayOfWeek: ', currentDayOfWeek);
  // console.log('currentMonth: ', currentMonth);
  // console.log('currentSumOfDaysInMonth: ', currentSumOfDaysInMonth);
  // console.log('currentArrDaysInMonth: ', currentArrDaysInMonth);
  // console.log('currentArrDayNames: ', currentArrDayNames);
  // console.log('currentYear: ', currentYear);
  // console.log('currentArrYears: ', currentArrYears);
  // console.log('activeYear: ', activeYear);
  // console.log('activeMonth: ', activeMonth);
  // console.log('activeDay: ', activeDay);

  // setting active year
  const setActiveYearOnClick = (year) => {
    setActiveYear(year);
  };
  // setting active month
  const setActiveMonthOnClick = (month) => {
    setActiveMonth(month);
  };
  // setting active day
  const setActiveDayOnClick = (day) => {
    setActiveDay(day);
  };

  // scroll to active day on first render
  const dayRef = useRef([]);
  // console.log('dayRef: ', dayRef);
  const addToRefs = (el) => {
    dayRef.current.push(el);
    // console.log('dayRef.current.length: ', dayRef.current.length);
    // console.log('activeDay: ', activeDay);
    if (dayRef.current.length === activeDay) {
      dayRef.current[activeDay - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      // console.log('executing scroll');
    }
    // console.log('dayRef.current[activeDay]: ', dayRef.current[activeDay]);
  };
  // const resetDayRef = () => {
  //   console.log('running resetDayRef');
  //   dayRef.current = [];
  // };

  return (
    <>
      <div className="calendar__dropdownContainer">
        <DropdownContainer
          activeItem={activeYear}
          heightFactor={34}
          width={'40%'}
          dropdownItems={currentArrYears}
          onClick={setActiveYearOnClick}
        />
        <DropdownContainer
          activeItem={activeMonth}
          heightFactor={34}
          width={'60%'}
          dropdownItems={months}
          onClick={setActiveMonthOnClick}
        />
      </div>
      <Scrollbars thumbSize={100}>
        <Calendar
          currentArrDaysInMonth={currentArrDaysInMonth}
          currentArrDayNames={currentArrDayNames}
          activeDay={activeDay}
          setActiveDayOnClick={setActiveDayOnClick}
          dayRef={dayRef}
          addToRefs={addToRefs}
        />
      </Scrollbars>
    </>
  );
}
