import "./details.scss";
import tmpicon from "../../assets/img/icon.JPG";

const details_data = {
  icon_img: tmpicon,
  meeting_details: "Meeting Details",
  meeting_name: "Meeting Name",
  participant: "Participant",
  participants: "Participants",
  date: "13.01.2021",
  time: "19:30",
  leave: "Leave",
  restaurant: "Restaurant",
  meeting_text:
    "Description Text, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  aliqua.",
};

export default function Details() {
  return (
    <div className="d-details__container">
      <span className="meeting__details">{details_data.meeting_details}</span>
      <span className="meeting__name">{details_data.meeting_name}</span>
      <div>
        <img className="icontmp" src={details_data.icon_img} alt="icon" />
        <span className="participant__text">{details_data.participant}</span>
      </div>
      <div className="restaurant__info">
        <div className="restaurant__data">
          <img className="icontmp" src={details_data.icon_img} alt="icon" />
          <span className="restaurant__text">{details_data.restaurant}</span>
        </div>
        <div className="restaurant__data">
          <img className="icontmp" src={details_data.icon_img} alt="icon" />
          <span className="restaurant__text">{details_data.date}</span>
        </div>
        <div className="restaurant__data1">
          <img className="icontmp" src={details_data.icon_img} alt="icon" />
          <span className="restaurant__text">{details_data.time}</span>
        </div>
      </div>
      <span className="meeting__text">{details_data.meeting_text}</span>
      <span className="participants">{details_data.participants}</span>
      <div className="pariticipants__details">
        <img className="icontmp" src={details_data.icon_img} alt="icon" />
        <img className="icontmp" src={details_data.icon_img} alt="icon" />
        <img className="icontmp" src={details_data.icon_img} alt="icon" />
        <span className="leave">{details_data.leave}</span>
        <img className="icontmp" src={details_data.icon_img} alt="icon" />
      </div>
    </div>
  );
}
