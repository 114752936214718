import { useEffect, useState } from 'react';
import Input from './Input';
import Checkbox from './Checkbox';
import SocialButton from './SocialButton';
import './loginForm.scss';
import { ReactComponent as LogoLight } from '../../assets/logo/logo-light.svg';
import { ReactComponent as MeetUsIcon } from '../../assets/icons/meetus-app-icon.svg';
import { ReactComponent as GoogleLogo } from '../../assets/icons/google-g-logo.svg';
import { ReactComponent as FacebookLogo } from '../../assets/icons/facebook-f-logo.svg';
import { useNavigate } from "react-router-dom";

export default function LoginForm(props) {
  // styling submit button based on form input
  let navigate = useNavigate();
  const [emailInput, setEmailInput] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  function detectEmailInput(e) {
    setEmailInput(e.target.value);
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }
  useEffect(() => {
    navigate("/meetings");
    console.log('running conditional');
    console.log('emailInput', emailInput);
    console.log('passwordInput', passwordInput);
    if (emailInput !== '' && passwordInput !== '') {
      document.getElementById('loginSubmit').classList.add('submitProg100');
    } else if (emailInput !== '' || passwordInput !== '') {
      document.getElementById('loginSubmit').classList.add('submitProg50');
      document.getElementById('loginSubmit').classList.remove('submitProg100');
    } else {
      document.getElementById('loginSubmit').classList.remove('submitProg50');
      document.getElementById('loginSubmit').classList.remove('submitProg100');
    }
  });
  function googleClick() {
    alert('clicked Google Button!');
  }
  function facebookClick() {
    alert('clicked Facebook Button!');
  }
  return (
    <div className="loginFormContainer">
      <LogoLight className="logo--light" />
      <h2>meetUs for web is coming soon!</h2>
      <p>In the meantime, you can find our Android/iOS Apps here:</p>
      <a href="https://play.google.com/store/apps/details?id=com.eudaitec.meetusapp&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          style={{ width: 100 + '%' }}
          className="storeIconGoogle"
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
      <a href="https://apps.apple.com/us/app/meetusapp/id1601812533">
        <img
          style={{ width: 100 + '%' }}
          className="storeIconApple"
          alt="Get it on Apple Store"
          src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png"
        />
      </a>
      <form id="loginForm">
        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
        <Input
          type="password"
          inputId="password"
          required="required"
          labelId="loginPasswordLabel"
          labelString="PASSWORD"
          detectInput={detectPasswordInput}
        />
      </form>
      {/* <div className="socialContainer">
        <SocialButton
          icon={<GoogleLogo />}
          buttonClick={googleClick}
          className="google"
          border="1px solid #F2F2F2"
        />
        <SocialButton
          icon={<FacebookLogo />}
          buttonClick={facebookClick}
          className="facebook"
          border="none"
        />
        <SocialButton
          icon={'Guest'}
          buttonClick={props.getToken}
          className="guest"
          border="none"
        />
      </div> */}
      <div className="checkboxContainer">
        <Checkbox labelText="Stay signed in" />
      </div>
      <button type="submit" form="loginForm" id="loginSubmit">
        <MeetUsIcon />
      </button>
      <a href="#test">Can't sign in?</a>
      <a href="#test">Create Account</a>
    </div>
  );
}
