import './occasions.scss';

import Occasion from './Occasion';
import { ReactComponent as MeetUsAppIcon } from '../../assets/icons/meetus-app-icon.svg';

const OccasionData = [
  {
    name: 'Restaurant',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
  {
    name: 'Bar',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
  {
    name: 'Café',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
  {
    name: 'Coworking',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
  {
    name: 'Club',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
  {
    name: 'Random',
    icon: <MeetUsAppIcon className={'occasionIcon'} />,
  },
];

export default function OccasionsContainer(props) {
  return (
    <div className="occasionsContainer">
      {OccasionData.map((item) => {
        return <Occasion key={item.name} name={item.name} icon={item.icon} />;
      })}
    </div>
  );
}
