import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { Paths } from "./routes/Paths";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Meetings from "./pages/Meetings";
import LoginForm from "./components/forms/LoginForm";

export default function App() {
  // const [token, setToken] = useState(false);

  // function guestClick() {
  //   setToken(true);
  // }

  // if (!token) {
  //   return <Login setToken={setToken} getToken={guestClick} />;
  // }

  return (
    <Router>
      <Routes>
        <Route path="/"></Route>
        <Route path={Paths.Dashboard.path} element={<Dashboard/>} />
        <Route path={Paths.Meetings.path} element={<Meetings/>} />
        <Route path={Paths.Login.path} element={<Login/>} />
      </Routes>
    </Router>
  );
}
