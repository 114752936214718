import NavItem from './NavItem';

import { ReactComponent as LogoLight } from '../../assets/logo/logo-light.svg';

const NavItems = [
  {
    name: 'Dashboard',
    to: '/',
    //   icon: <TypographyMenu className={'navSidebarItemIcon'} />,
  },
  {
    name: 'Meetings',
    to: '/meetings',
    //   icon: <SupportMenu className={'navSidebarItemIcon'} />,
  },
];

export default function Navbar() {
  return (
    <div>
      <LogoLight className="logo--light" />
      <div className="navbar">
        {NavItems.map((item) => {
          return (
            <NavItem
              key={item.name}
              name={item.name}
              to={item.to}
              // icon={item.icon}
            />
          );
        })}
      </div>
    </div>
  );
}
