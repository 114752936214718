import { forwardRef } from 'react';
import Day from './Day';

const Calendar = forwardRef((props, ref) => {
  return (
    <div className="calendar">
      {props.currentArrDaysInMonth.map((day, index) => {
        let weekday = props.currentArrDayNames[index];
        return (
          <div key={day} ref={props.addToRefs}>
            <Day
              className={
                props.activeDay === day
                  ? 'day--active day__container'
                  : 'day__container'
              }
              setActiveDayOnClick={props.setActiveDayOnClick}
              dayOfMonth={day}
              dayOfWeek={props.currentArrDayNames[index]}
            />
            {weekday === 'Sun' ? <div className="day__separator"></div> : null}
          </div>
        );
      })}
    </div>
  );
});
export default Calendar;
