import star from '../../assets/img/star.jfif';

const data = {
  star_img: star,
  meeting_name: 'Meeting Name',
  datetime: '13th Jan 2021 - 19:30',
};

export default function Card(props) {
  return (
    <div className="card">
      <div className="card__top">
        <img src={props.photos} alt={props.name} />
        <div className="rating__pan">
          <img
            className="rating__pan__star__img"
            src={data.star_img}
            alt="star"
          />
          <span>
            {props.rating} | {props.user_ratings_total}
          </span>
        </div>
      </div>
      <div className="bottom__banner">
        <h3 className="card__meetingName">{data.meeting_name}</h3>
        <h4 className="card__date__time">{data.datetime}</h4>
        <div className="card__location">
          <img
            className="location__icon"
            src={props.icon}
            width="20"
            height="20"
            alt="icon"
          />
          <div className="location__name">{props.name}</div>
        </div>
      </div>
    </div>
  );
}
