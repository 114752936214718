export default function Day(props) {
  return (
    <div
      date={props.date}
      // disabled={disabled}
      className={props.className}
      onClick={() => props.setActiveDayOnClick(props.dayOfMonth)}
    >
      <div className="day__meta">
        <h4>{props.dayOfWeek}</h4>
        <h3>{props.dayOfMonth}</h3>
      </div>

      <div className="day__meeting__count">
        <h4>No Meetings</h4>
      </div>
    </div>
  );
}
