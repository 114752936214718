import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import ProgressMeter from '../progressMeter/ProgressMeter';
import Input from '../forms/Input';
import OccasionsContainer from '../occasions/OccasionsContainer';
import PickerContainer from '../picker/PickerContainer';
import Button from '../buttons/Button';

export default function Overlay(props) {
  const [meetingTitle, setMeetingTitle] = useState();
  function detectTitleInput(e) {
    setMeetingTitle(e.target.value);
  }
  const contentAnim = {
    initial: {
      opacity: 0,
      left: '20%',
    },
    isOpen: {
      opacity: 1,
      left: '0%',
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    exit: { opacity: 0, left: '-20%' },
  };
  return (
    <>
      <h3>Create Meeting</h3>
      <ProgressMeter
        width="70%"
        steps={props.steps}
        progress={props.progress}
      />
      <div className="modalContentContainer">
        {/* Step 1 */}
        <AnimatePresence mode='wait'>
          {props.progress === 0 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={'initial'}
              animate={'isOpen'}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={'exit'}
            >
              <Input
                type="text"
                inputId="meetingTitle"
                required="required"
                labelId="meetingTitleLabel"
                labelString="MEETING TITLE"
                detectInput={detectTitleInput}
              />
              <OccasionsContainer />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode='wait'>
          {/* Step 2 */}
          {props.progress === 1 && (
            <motion.div
              className="modalContent"
              variants={contentAnim}
              initial={'initial'}
              animate={'isOpen'}
              transition={{
                default: { duration: 0.5 },
              }}
              exit={'exit'}
            >
              <div className="timelineContainer">
                <h3>Start</h3>
                <PickerContainer />
              </div>
              <h3>End</h3>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="buttonGroup">
        <Button
          name={props.progress === 0 ? 'Cancel' : 'Back'}
          buttonClick={
            props.progress === 0 ? props.toggleMeetingModal : props.prevStep
          }
          className="plain buttonPrev"
        />
        <Button
          name={'Confirm'}
          buttonClick={props.nextStep}
          className="plain"
        />
      </div>
    </>
  );
}
