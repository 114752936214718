import Details from './Details';
import Button from '../buttons/Button';
import { Scrollbars } from 'react-custom-scrollbars';

import { ReactComponent as MeetUsAppIcon } from '../../assets/icons/meetus-app-icon.svg';

export default function DetailsContainer() {
  function btnClick() {
    alert('clicked Google Button!');
  }
  return (
    <Scrollbars thumbSize={200}>
      <div>
        <Details />
        <div className="btn_vote">
          <Button
            name={'Vote Results'}
            icon={<MeetUsAppIcon className="button__icon" />}
            buttonClick={btnClick}
          />
        </div>
      </div>
    </Scrollbars>
  );
}
