import React, { useState, useEffect, useRef } from 'react';

import './picker.scss';
import Picker from './Picker';

const years = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export default function PickerContainer(props) {
  const [yearIsOpen, setYearIsOpen] = useState(false);
  const toggleYearIsOpen = () => setYearIsOpen((value) => !value);
  const [year, setYear] = useState('2021');
  function setActiveYearOnClick(year) {
    setYear(year);
  }
  const [monthIsOpen, setMonthIsOpen] = useState(false);
  const toggleMonthIsOpen = () => setMonthIsOpen((value) => !value);
  const [month, setMonth] = useState('January');
  function setActiveMonthOnClick(month) {
    setMonth(month);
  }
  const [dayIsOpen, setDayIsOpen] = useState(false);
  const toggleDayIsOpen = () => setDayIsOpen((value) => !value);
  const [day, setDay] = useState('1');
  function setActiveDayOnClick(day) {
    setDay(day);
  }

  return (
    <>
      <Picker
        content={years}
        variable={year}
        isOpen={yearIsOpen}
        toggleIsOpen={toggleYearIsOpen}
        onClick={setActiveYearOnClick}
        buttonWidth={'60px'}
        overlayWidth={'120px'}
      />
      <Picker
        content={months}
        variable={month}
        isOpen={monthIsOpen}
        toggleIsOpen={toggleMonthIsOpen}
        onClick={setActiveMonthOnClick}
        buttonWidth={'110px'}
        overlayWidth={'160px'}
      />
      <Picker
        content={days}
        variable={day}
        isOpen={dayIsOpen}
        toggleIsOpen={toggleDayIsOpen}
        onClick={setActiveDayOnClick}
        buttonWidth={'40px'}
        overlayWidth={'90px'}
      />
    </>
  );
}
