import { Scrollbars } from 'react-custom-scrollbars';
import jsondata from '../../logic/testData/getPlaces';

import Card from './Card';
import Button from '../buttons/Button';

import { ReactComponent as MeetUsAppIcon } from '../../assets/icons/meetus-app-icon.svg';

export default function MeetingCardContainer(props) {
  return (
    <Scrollbars thumbSize={200}>
      <div className="card__scrollbarCont">
        {jsondata.body[0].places.map((bodyData, index) => {
          return (
            <Card
              key={index}
              photos={bodyData.photos}
              name={bodyData.name}
              rating={bodyData.rating}
              user_ratings_total={bodyData.user_ratings_total}
              icon={bodyData.icon}
            />
          );
        })}
        <div className="create__meeting__btn">
          <Button
            name={'Create Meeting'}
            width="13rem"
            icon={<MeetUsAppIcon className="button__icon" />}
            buttonClick={props.toggleMeetingModal}
          />
        </div>
      </div>
    </Scrollbars>
  );
}
