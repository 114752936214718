import { useState } from 'react';

export default function Occasion(props) {
  return (
    <div className="occasionItem">
      {props.icon}
      <span>{props.name}</span>
    </div>
  );
}
